<template>
  <div class="container">
    <div class="footer">
      <div class="footer_top">
        <div class="top_item">
          <img src="@/assets/img/icon_footer1.png" alt />
          <div>正规渠道，正品保障</div>
        </div>
        <div class="top_item">
          <img src="@/assets/img/icon_footer2.png" alt />
          <div>品类齐全，轻松购物</div>
        </div>
        <div class="top_item">
          <img src="@/assets/img/icon_footer3.png" alt />
          <div>顺丰快递，极速配送</div>
        </div>
        <div class="top_item">
          <img src="@/assets/img/icon_footer4.png" alt />
          <div>全网低价，愉快选购</div>
        </div>
        <div class="top_item">
          <img src="@/assets/img/icon_footer5.png" alt />
          <div>退换无忧，购买无忧</div>
        </div>
      </div>
      <div class="footer_bottom">
        <div @click="linkDownload('https://beian.miit.gov.cn')" class="cursor">{{ config.record }}</div>
        <div
          @click="linkDownload(config.internet_record_url)"
          class="cursor"
        >{{ config.internet_record }}</div>
        <div>Copyright © 2016-{{ date.getFullYear() }} {{ config.copy_right }} 版权所有</div>
        <div>电话：{{ config.platform_phone }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "myfooter",
  data() {
    return {
      config: {},
      date: new Date()
    };
  },

  methods: {
    linkDownload(e) {
      window.open(e);
    },
    webConfigDetail() {
      this.http.webConfigDetail().then(res => {
        if (res.code == 200) {
          this.config = res.data.config;
        } else {
          reject();
          that.$message.error(res.msg);
        }
      });
    }
  },
  mounted() {
    this.webConfigDetail();
  },
  watch: {}
};
</script>
<style scoped>
.container {
  width: 100%;
  background: #f4f4f4;
  border-top: 1px solid #ee0707;
  /* position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99; */
}

.footer {
  width: 1200px;
  height: 120px;
  margin: auto;
}

.footer_top {
  display: flex;
  margin-top: 19px;
  justify-content: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #444444;
}

.top_item {
  display: flex;
  align-items: center;
}

.top_item img {
  width: 38px;
  height: 44px;
  margin-right: 9px;
}

.top_item {
  margin-right: 29px;
}

.top_item:last-child {
  margin-right: 0;
}

.footer_bottom {
  display: flex;
  margin-top: 19px;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #b2b2b2;
}

.footer_bottom div {
  margin-right: 40px;
}

.footer_bottom div:last-child {
  margin-right: 0;
}
</style>
