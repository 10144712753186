import axios from "axios"
import router from './../router'
const ElementUI = require('element-ui')
axios.defaults.baseURL = process.env.VUE_APP_URL
axios.interceptors.request.use(config => {
	//登录+获取验证码不加token
	if (config.url == '/sms/send-captcha' || config.url == '/staff/auth/login') {

	} else if (config.url == '/auth/register' || config.url == '/auth/forget-password') {
		// 注册+忘记密码
		if (sessionStorage.getItem('signature')) {
			config.headers.signature = JSON.parse(sessionStorage.getItem('signature'))
		}
	} else {
		if (sessionStorage.getItem('token')) {
			config.headers.token = JSON.parse(sessionStorage.getItem('token'))
		}
	}
	return config;
}, error => {
	ElementUI.Message({
		message: '请求错误！',
		type: 'error'
	});
});
axios.interceptors.response.use(response => {
	if (response.data.code == 401001) {
		//token过期
		ElementUI.Message({
			message: '身份已失效,请重新登录！',
			type: 'error'
		});
		localStorage.clear();
		sessionStorage.clear()
		router.push({
			name: 'login'
		});
		return
	} else if (response.data.code == 404001) {
		ElementUI.Message({
			message: '无法访问！',
			type: 'error'
		});
		localStorage.clear();
		sessionStorage.clear()
		router.push({
			name: 'login'
		});
		return
	} else {
		return response.data
	}
}, error => {
	return Promise.reject(error);
})
/**
 * 函数的参数及返回值如下：
 * @param {String} method  请求的方法：get、post、delete、put
 * @param {String} url     请求的url:
 * @param {Object} data    请求的参数
 * @returns {Promise}     返回一个promise对象，其实就相当于axios请求数据的返回值
 */
export default function (method, url, data = null) {
	method = method.toLowerCase();
	if (method == 'post') {
		return axios.post(url, data)
	} else if (method == 'get') {
		return axios.get(url, { params: data })
	} else if (method == 'delete') {
		return axios.delete(url, { params: data })
	} else if (method == 'put') {
		return axios.put(url, data)
	} else {
		console.error('未知的method' + method)
		return false
	}
}

